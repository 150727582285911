import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { GatherLogs } from '../gather'

const Ga = () => {
    return (
        <L10nProvider>
            <GatherLogs/>
        </L10nProvider>
    )
}

export default Ga;
